<template>
<!-- 户外运动排名  左侧-->
	<div>
		<div class="container darkBack">
			<!-- 上方数据内容 -->
			<div class="sportData borderBack">
				<div class="lightBlueFont font14">{{run.title}}</div>
				<div class="blueFont font26">
					<countTo :startVal='0' :endVal='run.num'  :decimals="2" :duration='3000'></countTo>公里
				</div>
			</div>
			<div class="sportData borderBack">
				<div class="lightBlueFont font14">{{foot.title}}</div>
				<div class="blueFont font26">
					<countTo :startVal='0' :endVal='foot.num' :duration='3000'></countTo>公里
				</div>
			</div>
			<div class="sportData borderBack">
				<div class="lightBlueFont font14">{{cycling.title}}</div>
				<div class="blueFont font26">
					<countTo :startVal='0' :endVal='cycling.num'  :decimals="2" :duration='3000'></countTo>公里
				</div>
			</div>
			 
			 <!-- 下方图表 -->
			 <div class="chartContainer">
				 <div class="blueFont">● 户外运动总览</div>
				 <div id="overview"></div>
			 </div>
		</div>
	</div>
</template>

<script>
	// 引入数字滚动组件
import countTo from 'vue-count-to';
  export default {
    components: { countTo },
		data () {
			return {
				run:{},
				foot:{},
				cycling:{},
				pandect:{
					yAxis:['新昌县', '诸暨市', '柯桥区', '越城区', '上虞区','嵊州市'],
					data:[1,3,5],
				}
			}
		},
		methods:{
			// 绘制户外运动总览表
			drawOverview(number){
				let that = this;
				let myChart = this.$echarts.init(document.getElementById("overview"));
				
				let option = {
					xAxis: {
						max: 'dataMax',
						// 不显示网格线
						splitLine:{
							show: true,
							lineStyle:{
								color:'#2D94D033',
							}
						},
						// 不显示刻度值
						axisLabel:{show:false}
					},
					grid: {         // 间距
						left: '6%',  
						right: '12%', 
						top:'8%', 
						bottom: '6%',  
						containLabel: true,
					},
					yAxis: {
						type: 'category',
						data: number.yAxis,
						inverse: true,
						animationDuration: 300,
						animationDurationUpdate: 300,
						//y轴显示及颜色
						axisLine:{
							show:true,
							lineStyle:{
								color:'#2D94D0',
							}
						},
						// 刻度不显示
						axisTick:{show:false},
						// y轴字体样式
						axisLabel:{
							color:'#7EA7E2',
							fontSize:"0.14rem "
						},
					},
					series: [{
						// 实时排序
						realtimeSort: true,
						type: 'bar',
						data: number.data,
						// 柱子宽度
						barWidth:'40%',
						// 柱子间距
						barCategoryGap:10,
						// 数据显示
						label: {
							show: true,
							position: 'top',
							color:"#F1F1F1",
							valueAnimation: true,
                            fontSize:'0.14rem',
						},
						itemStyle: {
							normal: {
								color: this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [{
									offset: 0,
									color: "#2D94D0" // 0% 处的颜色
								}, {
									offset: 1,
									color: "#3AE6CE" // 100% 处的颜色
								}], false)
							}
						},
					}],
					animationDuration: 0,
					animationDurationUpdate: 3000,
					animationEasing: 'linear',
					animationEasingUpdate: 'linear'
				};
				
				// 使用刚指定的配置项和数据显示图表。
				myChart.setOption(option);
				// 图表自动缩放
				window.addEventListener("resize",function(){
				  myChart.resize();
				});
			},
			getOutDoorData(){
				let url = window.appHost+"/api/v1/screen/Outdoors/vital"
        		let result = window.ajaxRequest(url)
				// console.log(result)
				this.run=result.data[0]
				this.foot=result.data[1]
				this.cycling=result.data[2]
			},
			getSportData(){
				let url = window.appHost+"/api/v1/screen/Outdoors/area"
        		let result = window.ajaxRequest(url)
				// console.log(result)
				this.pandect={
						yAxis:[],
						data:[]
				}
				for(key in result.data){
					// console.log(key)
					// console.log(result.data[key])
					this.pandect.yAxis.push(key)
					this.pandect.data.push(result.data[key])
				}
				// console.log(this.pandect)
				this.drawOverview(this.pandect);
			}
		},
		mounted() {
			this.getOutDoorData()
			this.getSportData()
		}
	}
</script>

<style scoped>
	.font26{font-size: 0.26rem}
	.container{
		width: 4.37rem;
		height: 8.55rem;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: 0.1rem;
	}
	.sportData{
		width: 3.54rem;
		height: 0.93rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-top: 0.2rem;
	}
	.chartContainer{
		width: 3.5rem;
		padding-top: 0.5rem;
	}
	#overview{
		width: 4.20rem;
		height: 4.00rem;
		/* border:1px solid red; */
		margin-left:-0.4rem;
	}
</style>